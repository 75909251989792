import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
import Home from "../views/HomeView.vue";
import Categoria from "../views/ACategoria.vue";
import Novidades from "../views/Novidades.vue";
import Galeria from "../views/Galeria.vue";
import Etapas from "../views/Etapas.vue";
import ClassificacaoGeral from "../views/ClassificacaoGeral.vue";
import Pilotos from "../views/Pilotos.vue";
import DetalhePiloto from "../views/DetalhePiloto.vue";
import Equipes from "../views/Equipes.vue";
import DetalheEquipe from "../views/DetalheEquipe.vue";
import Circuitos from "../views/Circuitos.vue";
import DetalheCircuito from "../views/DetalheCircuito.vue";
import Noticia from "../views/Noticia.vue";
import Montadoras from "../views/Montadoras.vue";
import LoginStockClub from "../views/LoginStockClub.vue";
import RecuperarSenha from "../views/RecuperarSenha.vue";
import RedefinicaoSenha from "../views/RedefinicaoSenha.vue";
import AtualizacaoSenha from "../views/AtualizacaoSenha.vue";
import ConfirmacaoRegistro from "../views/ConfirmacaoRegistro.vue";
import AtivacaoConta from "../views/AtivacaoConta.vue";
import CriarConta from "../views/CriarConta.vue";
import PilotoFavorito from "../views/PilotoFavorito.vue";
import UsuarioLogado from "../views/UsuarioLogado.vue";
import MeuPerfil from "../views/MeuPerfil.vue";
import VotacaoPiloto from "../views/VotacaoPiloto.vue";
import VotacaoPilotoPreview from "../views/VotacaoPilotoPreview.vue";
import PreVotacao from "../views/PreVotacao.vue";
import PoliticaPrivacidade from "../views/PoliticaPrivacidade.vue";
import TermosDeUso from "../views/TermosDeUso.vue";
import PendenteAtivacao from "../views/PendenteAtivacao.vue";
// import Galeao from "../views/Galeao.vue";
import BuenosAires from "../views/BuenosAires.vue";
import BeloHorizonte from "../views/BeloHorizonte.vue";
import PageEsg from "../views/PageEsg.vue";
import TrabalheConosco from "../views/TrabalheConosco.vue";
import CadastroTrabalheConosco from "../views/CadastroTrabalheConosco.vue";
import ObrigadoTrabalheConosco from "../views/ObrigadoTrabalheConosco.vue";
import TermosMenores from "../views/TermosMenores.vue";
import Programacao from "../views/Programacao.vue";
import CamaroteB2B from "../views/CamaroteB2B.vue";
import CorporativaB2B from "../views/CorporativaB2B.vue";
import SejaPatrocinador from "../views/SejaPatrocinador.vue";
import PatrocinadorCadastro from "../views/PatrocinadorCadastro.vue";
import StockChannelInterna from "../views/StockChannelInterna.vue";
import VotacaoTempoReal from "../views/VotacaoTempoReal.vue";
import ItemCarrosselStockChannel from "../components/ItemCarrosselStockChannel.vue";
import Historia from "../views/Historia.vue";
import HistoriaBH from "../views/HistoriaBH.vue";
import FanpushTV from "../views/FanpushTV.vue";
import HallDaFama from "../views/HallDaFama.vue";
import StockQuiz from "../views/StockQuiz.vue";
import StartQuiz from "../views/StartQuiz.vue";
import ProgramacaoHome from "../views/ProgramacaoHome.vue";

Vue.use(VueRouter);

import {
  EventBus
} from "@/main.js";

const routes = [{
    path: "/",
    name: "index",
    component: Home,
    // component: () => import('../views/HomeView.vue'),
    redirect: () => {
      return {
        path: "/stockcar/proseries",
      };
    },
  },

  {
    path: "/stockcar/",
    redirect: "/stockcar/proseries",
  },

  {
    path: "/:categoria",
    redirect: "/stockcar/:categoria",
  },

  {
    path: "/fanpush",
    name: "fanpush",
    redirect: () => {
      return (window.location.href = "https://club.stockproseries.com.br/");
    },
  },
  {
    path: "/stockcar/:categoria",
    name: "Home",
    component: Home,
    // component: () => import('../views/HomeView.vue'),
  },

  {
    path: "/stockcar/:categoria/fanpush-tv",
    name: "Fan Push TV",
    component: FanpushTV,
  },

  {
    path: "/stockcar/:categoria/categoria",
    name: "Categoria",
    component: Categoria,
    // component: () => import('../views/ACategoria.vue')
  },

  {
    path: "/stockcar/:categoria/termo-menor-idade",
    name: "Termo para menores",
    component: TermosMenores,
    // component: () => import('../views/TermosMenores.vue')
  },

  {
    path: "/stockcar/proseries/galeao",
    name: "GP Galeao",
    // component: Galeao,
    component: () => import('../views/Galeao.vue')
  },

  {
    path: "/stockcar/proseries/belo-horizonte",
    name: "GP Belo Horizonte",
    component: BeloHorizonte,
  },

  {
    path: "/stockcar/proseries/buenos-aires",
    name: "GP Buenos Aires",
    component: BuenosAires,
  },
  {
    path: "/stockcar/proseries/historia-buenosaires",
    name: "GP Buenos Aires",
    component: Historia,
  },

  {
    path: "/stockcar/proseries/historia-bh",
    name: "GP Belo Horizonte",
    component: HistoriaBH,
  },

  {
    path: "/stockcar/proseries/hall-of-fame",
    name: " Hall of Fame",
    component: HallDaFama,
  },

  {
    path: "/stockcar/proseries/stock-quiz",
    name: "Stock Quiz",
    component: StockQuiz,
  },

  {
    path: "/stockcar/proseries/start-quiz",
    name: "Stock Quiz",
    component: StartQuiz,
  },

  {
    path: "/stockcar/:categoria/esg",
    name: "ESG",
    component: PageEsg,
  },

  {
    path: "/stockcar/:categoria/trabalhe-conosco",
    name: "Trabalhe Conosco",
    component: TrabalheConosco,
  },
  {
    path: "/stockcar/:categoria/trabalhe-conosco/cadastro",
    name: "Banco de Talentos ",
    component: CadastroTrabalheConosco,
  },
  {
    path: "/stockcar/:categoria/trabalhe-conosco/obrigado",
    name: "Obrigado por se cadastrar ",
    component: ObrigadoTrabalheConosco,
  },
  {
    path: "/stockcar/:categoria/camarotes-b2b/:id_camarote",
    name: "Camarotes B2B item",
    component: CamaroteB2B,
    // component: () => import('../views/CamaroteB2B.vue')
  },

  {
    path: "/stockcar/:categoria/corporativa-b2b",
    name: "Camarotes B2B",
    component: CorporativaB2B,
    // component: () => import('../views/CorporativaB2B.vue')
  },

  {
    path: "/stockcar/:categoria/seja-patrocinador",
    name: "Seja Patrocinador",
    component: SejaPatrocinador,
    // component: () => import('../views/SejaPatrocinador.vue')
  },

  {
    path: "/stockcar/:categoria/quero-ser-patrocinador",
    name: "Quero ser patrocinador",
    component: PatrocinadorCadastro,
    // component: () => import('../views/PatrocinadorCadastro.vue')
  },

  {
    path: "/stockcar/:categoria/novidade",
    name: "Novidades",
    component: Novidades,
    // component: () => import('../views/Novidades.vue')
  },
  {
    path: "/stockcar/:categoria/galeria/:id",
    name: "Galeria",
    component: Galeria,
    // component: () => import('../views/Galeria.vue')
  },
  {
    path: "/stockcar/:categoria/programacao/:idCircuito",
    name: "Programacao",
    component: Programacao,
    // component: () => import('../views/Programacao.vue')
  },

  {
    path: "/stockcar/:categoria/programacao/",
    name: "ProgramacaoHome",
    component: ProgramacaoHome,
    // component: () => import('../views/Programacao.vue')
  },
  
  {
    path: "/stockcar/:categoria/etapa",
    name: "Etapas",
    component: Etapas,
    // component: () => import('../views/Etapas.vue')
  },
  {
    path: "/stockcar/:categoria/classificacao",
    name: "Classificação Geral",
    component: ClassificacaoGeral,
    // component: () => import('../views/ClassificacaoGeral.vue')
  },
  {
    path: "/stockcar/:categoria/piloto",
    name: "Pilotos",
    component: Pilotos,
    // component: () => import('../views/Pilotos.vue')
  },
  {
    path: "/stockcar/:categoria/piloto/:id",
    name: "DetalhePiloto",
    component: DetalhePiloto,
    // component: () => import('../views/DetalhePiloto.vue')
  },
  {
    path: "/stockcar/:categoria/equipe",
    name: "Equipes",
    component: Equipes,
    // component: () => import('../views/Equipes.vue')
  },
  {
    path: "/stockcar/:categoria/equipe/:id",
    name: "DetalheEquipe",
    component: DetalheEquipe,
    // component: () => import('../views/DetalheEquipe.vue')
  },
  {
    path: "/stockcar/:categoria/circuito",
    name: "Circuitos",
    component: Circuitos,
    // component: () => import('../views/Circuitos.vue')
  },
  {
    path: "/stockcar/:categoria/circuito/:id",
    name: "DetalheCircuito",
    component: DetalheCircuito,
    // component: () => import('../views/DetalheCircuito.vue')
  },
  {
    path: "/stockcar/:categoria/noticia/:id",
    name: "Noticia",
    component: Noticia,
    // component: () => import('../views/Noticia.vue')
  },
  {
    path: "/stockcar/:categoria/montadora",
    name: "Montadoras",
    component: Montadoras,
    // component: () => import('../views/Montadoras.vue')
  },

  {
    path: "/stockclub/login",
    name: "StockClub | Login",
    component: LoginStockClub,
    // component: () => import('../views/LoginStockClub.vue')
  },
  {
    path: "/stockclub/esqueci-minha-senha",
    name: "StockClub | Recuperar Senha",
    component: RecuperarSenha,
    // component: () => import('../views/RecuperarSenha.vue')
  },
  {
    path: "/stockclub/redefinir-senha",
    name: "StockClub | Redefinição de Senha",
    component: RedefinicaoSenha,
    // component: () => import('../views/RedefinicaoSenha.vue')
  },
  {
    path: "/stockclub/atualizar-senha",
    name: "StockClub | Atualizar Senha",
    component: AtualizacaoSenha,
    meta: {
      login: true,
    },
  },
  {
    path: "/stockclub/confirmacao-registro",
    name: "StockClub | Confirmacão de Registro",
    component: ConfirmacaoRegistro,
    // component: () => import('../views/ConfirmacaoRegistro.vue')
  },
  {
    path: "/stockclub/ativacao-conta",
    name: "StockClub | Ativacão de Conta",
    component: AtivacaoConta,
    // component: () => import('../views/AtivacaoConta.vue')
  },
  {
    path: "/stockclub/cadastro",
    name: "StockClub | Criar Conta",
    component: CriarConta,
    // component: () => import('../views/CriarConta.vue')
  },
  {
    path: "/stockclub/piloto-favorito",
    name: "StockClub | Piloto Favorito",
    component: PilotoFavorito,
    // component: () => import('../views/PilotoFavorito.vue')
    meta: {
      login: true,
    },
  },
  {
    path: "/stockclub/votacao-fanpush-live",
    name: "StockClub | Votação Fanpush",
    component: VotacaoTempoReal,
    // component: () => import('../views/VotacaoPiloto.vue')
    meta: {
      login: true,
    },
  },

  {
    path: "/stockclub/home",
    name: "StockClub | Home",
    component: UsuarioLogado,
    // component: () => import('../views/UsuarioLogado.vue')
    meta: {
      login: true,
    },
  },
  {
    path: "/stockclub/meu-perfil",
    name: "StockClub | Meu Perfil",
    component: MeuPerfil,
    // component: () => import('../views/MeuPerfil.vue')
    meta: {
      login: true,
    },
  },
  {
    path: "/stockclub/pendente-ativacao",
    name: "StockClub | Pendente",
    component: PendenteAtivacao,
  },
  {
    path: "/stockclub/pre-votacao",
    name: "StockClub | Pré Votação",
    component: PreVotacao,
    // component: () => import('../views/PreVotacao.vue')
    meta: {
      login: true,
    },
  },
  {
    path: "/stockclub/votacao-fanpush",
    name: "StockClub | Votação Piloto",
    component: VotacaoPiloto,
    // component: () => import('../views/VotacaoPiloto.vue')
    meta: {
      login: true,
    },
  },
  {
    path: "/stockclub/stockchannel",
    name: "StockClub | Stock Channel",
    component: StockChannelInterna,
    // component: () => import('../views/VotacaoPiloto.vue')
    meta: {
      login: true,
    },
  },
  {
    path: "/stockclub/channel/:id",
    name: "StockClub | Biblioteca Stock Channel",
    component: ItemCarrosselStockChannel,
    // component: () => import('../views/VotacaoPiloto.vue')
    meta: {
      login: true,
    },
  },
  {
    path: "/stockclub/preview-votacao-fanpush/:id_etapa",
    name: "StockClub | Preview Votação Piloto",
    component: VotacaoPilotoPreview,
  },
  {
    path: "/stockcar/:categoria/politica-privacidade",
    name: "Politica de Privacidade",
    component: PoliticaPrivacidade,
    // component: () => import('../views/PoliticaPrivacidade.vue')
  },
  {
    path: "/stockcar/:categoria/termos-de-uso",
    name: "Termos de Uso",
    component: TermosDeUso,
    // component: () => import('../views/TermosDeUso.vue')
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
});

router.beforeEach((to, from, next) => {
  let categoria = to.params.categoria;

  if (categoria != "formula-4") {
    store.dispatch("alterarIdioma", "pt-br");
  }

  if (categoria) {
    let title = categoria == "proseries" ? "Pro-series" : categoria;
    document.title = `${title.replace(/-/g, " ").replace(/\b\w/g, function (l) {
      return l.toUpperCase();
    })} | ${to.name}`;

    const localCategoria = sessionStorage.categoria ?
      JSON.parse(sessionStorage.categoria) :
      null;
    const colorLocal = localCategoria ?
      localCategoria.tag.replace(/-/g, "") :
      null;

    if (!colorLocal || !colorLocal.includes(categoria.replace(/-/g, ""))) {
      EventBus.$emit("updateRouter", categoria);
    }


  } else {
    document.title = to.name;
  }

  if (to.query.stockclub) {
    next("/stockclub/meu-perfil");
  }

  if (to.matched.some((record) => record.meta.login)) {
    if (!window.localStorage.token) {
      next("/stockclub/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;